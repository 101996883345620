
import HeaderPage from "@/view/content/widgets/engage/HeaderPage.vue";
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

import { API, Storage, graphqlOperation } from "aws-amplify";
import { listPageTables } from "@/graphql/queries";

export default defineComponent({
  name: "faq",
  components: { HeaderPage },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Caldeira Cloud", "Como Funciona");
    });
  },
  mounted() {
    this.loadPage();
  },
  data() {
    return {
      pageId: "caldeiracloud_faq_pagetable",
      faq: {
        banner: "",
        bannerSmall: "",
        metadata: {
          context: [],
          how_works: [],
        },
      },
    };
  },
  methods: {
    async loadPage() {
      try {
        const response: any = await API.graphql(
          graphqlOperation(listPageTables, {
            filter: {
              pageId: {
                eq: this.pageId,
              },
              enabled: {
                eq: true,
              },
            },
          })
        );

        if (response.data.listPageTables.items.length > 0) {
          this.faq = response.data.listPageTables.items[0];
          if (this.faq) {
            const banner = await Storage.get(this.faq.banner, {
              level: "public",
            });
            this.faq.banner = banner;

            const bannerSmall = await Storage.get(this.faq.bannerSmall, {
              level: "public",
            });
            this.faq.bannerSmall = bannerSmall;

            let metadata = JSON.parse(
              response.data.listPageTables.items[0].metadata
            );

            this.faq.metadata = metadata;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
});
